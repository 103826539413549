import { Navigate } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import { UserContext } from "../contexts/User"

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const userContext = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const checkUser = async () => {
      if (await userContext?.getUser()) {
        setIsAuthenticated(true)
      }
      setLoading(false)
    }
    checkUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  if (loading) return <div>Loading...</div>

  return isAuthenticated ? children : <Navigate to="/login" replace />
}

export default ProtectedRoute
