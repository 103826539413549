import Availability from "../pages/availability"
import AppointmentList from "../pages/appointments"
import PatientList from "../pages/patients/patientList"
import Login from "../pages/login"
import { Route, Routes } from "react-router-dom"
import ProtectedRoute from "./protectedRoute"

const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <AppointmentList />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/patients"
        element={
          <ProtectedRoute>
            <PatientList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/availability"
        element={
          <ProtectedRoute>
            <Availability />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<Login />} />
    </Routes>
  )
}

export default Router
