import moment from "moment"
import { PatientData } from "./pages/patients/patientList"

export const formatPhoneNumber = (str: string) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "")

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3]
  }

  return str
}

export const getInitials = (patient: PatientData) => {
  if (patient.firstname && patient.lastname)
    return (
      <div className="initials">
        {patient.firstname.charAt(0).toUpperCase()}
        {patient.lastname.charAt(0).toUpperCase()}
      </div>
    )
}

export const getFormattedDate = (date: string) =>
  new Date(date).toLocaleDateString("en-US")

export const getFormattedBirthDate = (date: string) =>
  moment(date).format("MM/DD/YY")

export const parsePhoneNumber = (phoneNumber: string): string => {
  return `+1${phoneNumber.replace(/\D+/g, "")}`
}
