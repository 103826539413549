import { Link } from "react-router-dom"
import { useContext } from "react"
import { UserContext } from "../contexts/User"

import "../styles/header.scss"

const Header = () => {
  const userContext = useContext(UserContext)

  return (
    <header>
      <div className="level is-mobile">
        <div className="level-left">
          <img
            src="/images/logo-white.svg"
            className="mb-0"
            alt="Commons clinic logo"
          />
        </div>
        <div className="level-item">
          <Link to="/">Appointments</Link>
          <Link to="/patients">Patients</Link>
          <Link to="/availability">Availability</Link>
        </div>
        <div className="level-right">
          <div className="level-item">
            <p className="mb-0">
              {userContext?.user?.attributes?.email ||
                userContext?.user?.attributes?.phone_number}
            </p>
          </div>
          <div className="level-item">
            <button className="button" onClick={userContext?.signOut}>
              Log out
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
