import { createContext, useState, useEffect, useMemo, ReactNode } from "react"
import Auth from "@aws-amplify/auth"

interface UserContextInterface {
  user: any
  resetPasswordPayload: any
  signOut: () => Promise<void>
  getUser: () => Promise<any>
  signIn: ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => Promise<boolean>
  confirmResetPassword: (newPassword: string) => Promise<void>
}

export const UserContext = createContext<UserContextInterface | null>(null)

function UserContextProvider({
  children,
  value,
}: {
  children: ReactNode
  value: {
    [key: string]: any
  }
}) {
  const [user, setUser] = useState<any>(null)
  const [resetPasswordPayload, setResetPasswordPayload] = useState(null)

  const getUser = async () => {
    try {
      const fetchedUser = await Auth.currentAuthenticatedUser()
      setUser(fetchedUser)
      return fetchedUser
    } catch (err) {
      return null
    }
  }

  useEffect(() => {
    setUser(getUser())
  }, [])

  // returns boolean value on whether sign in was successful
  const signIn = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    const signedInUser = await Auth.signIn(email, password)

    if (signedInUser?.challengeName === "NEW_PASSWORD_REQUIRED") {
      setResetPasswordPayload(signedInUser)
      return false
    } else {
      setUser(signedInUser)
    }

    return true
  }

  const signOut = async () => {
    await Auth.signOut()
    setUser(null)
    window.location.reload()
  }

  const confirmResetPassword = async (newPassword: string) => {
    await Auth.completeNewPassword(
      resetPasswordPayload, // the Cognito User Object
      newPassword // the new password
    )
    setResetPasswordPayload(null)
  }

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      getUser,
      signIn,
      signOut,
      resetPasswordPayload,
      confirmResetPassword,
      ...value,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [user, value, resetPasswordPayload]
  )

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}

UserContextProvider.defaultProps = {
  value: {},
}

export default UserContextProvider
