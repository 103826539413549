import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import Header from "./components/header"

import Amplify from "@aws-amplify/core"
import config from "./config"
import UserContextProvider from "./contexts/User"
import Router from "./components/router"

Amplify.configure({
  Auth: {
    region: config.aws.region,
    userPoolId: config.auth.userPoolId,
    userPoolWebClientId: config.auth.userPoolWebClientId,
    cookieStorage: {
      domain: " ", // Use a single space " " for host-only cookies
      expires: 2592000, // null means session cookies
      path: "/",
      secure: true, // for developing on localhost over http: set to false
      sameSite: "lax",
    },
    oauth: {
      domain: config.auth.userPoolDomain,
      scope: config.auth.scope,
      redirectSignIn: `https://${window.location.hostname}/parseauth`,
      redirectSignOut: `https://${window.location.hostname}/`,
      responseType: "code",
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserContextProvider>
        <Header />
        <Router />
      </UserContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)
