import { useState } from "react"
import Modal from "../../components/modal"
import { formatPhoneNumber, getFormattedBirthDate } from "../../utils"
import { ContentTitle, Package, PatientData, PhaseType } from "./patientList"
import UserIcon from "../../assets/icons/user.svg"
import Phone from "../../assets/icons/phone.svg"
import Email from "../../assets/icons/email.svg"
import Calendar from "../../assets/icons/calendar.svg"
import { adminInviteUser, updateUserContent } from "./services"

const DetailRow = ({ children, icon }: { children: any; icon?: any }) => (
  <div className="columns is-mobile">
    <div className="column is-1" style={{ width: 36, paddingRight: 0 }}>
      {icon && <img src={icon} alt="" style={{ marginBottom: 0 }} />}
    </div>
    <div className="column">{children}</div>
  </div>
)

type PatientModalType = {
  selectedPatient: PatientData
  contentTitle: ContentTitle
  packagesArr: Package[]
  closeModal: (updatedPatient?: PatientData, toastText?: string) => void
}

const PatientModal = ({
  selectedPatient,
  contentTitle,
  packagesArr,
  closeModal,
}: PatientModalType) => {
  const [modalError, setModalError] = useState<string>()
  const [patient, setPatient] = useState(selectedPatient)
  const [packages] = useState(packagesArr)

  const isSubmitDisabled = () => {
    if (patient.phase1 || patient.phase2 || patient.phase3) {
      return patient.currentPhase ? false : true
    }
    if (!patient.phase1 && !patient.phase2 && !patient.phase3) return true
  }

  const handleSubmit = async () => {
    setModalError(undefined)
    if (contentTitle === "Send Invite") {
      try {
        await adminInviteUser(patient)
        closeModal(patient, "Invite sent successfully!")
      } catch (err) {
        setModalError("Server error: Please contact technical support.")
        throw err
      }
    } else {
      try {
        await updateUserContent(patient)
        closeModal(patient, "Content updated successfully!")
      } catch (err) {
        setModalError("Server error: Please contact technical support.")
      }
    }
  }

  const handleChange = (packageID: string | null, phase: PhaseType) => {
    let currentPhase = patient.currentPhase
    if (packageID === "-1") {
      packageID = null
      if (patient.currentPhase === phase) {
        currentPhase = null
      }
    }
    switch (phase) {
      case "1": {
        setPatient({ ...patient, currentPhase, phase1: packageID })
        break
      }
      case "2": {
        setPatient({ ...patient, currentPhase, phase2: packageID })
        break
      }
      case "3": {
        setPatient({ ...patient, currentPhase, phase3: packageID })
        break
      }
    }
  }

  return (
    <Modal
      modalId="patient-modal"
      title={contentTitle}
      onClose={() => closeModal()}
    >
      <DetailRow icon={UserIcon}>
        {patient.firstname} {patient.lastname}
        <p style={{ color: "#999", marginBottom: 0 }}>
          {getFormattedBirthDate(patient.dob)}
        </p>
      </DetailRow>
      {patient.phoneNumber && (
        <DetailRow icon={Phone}>
          {formatPhoneNumber(patient.phoneNumber)}
        </DetailRow>
      )}
      {patient.email && <DetailRow icon={Email}>{patient.email}</DetailRow>}
      {patient.appointment && (
        <DetailRow icon={Calendar}>{patient.appointment}</DetailRow>
      )}
      <DetailRow>
        Care Planning Content:
        <select
          value={patient.phase1 || -1}
          onChange={e => handleChange(e.target.value, "1")}
        >
          <option value="-1">Select</option>
          {packages
            ?.filter(p => p.packageFields.phase === "1")
            .map(p => (
              <option key={p.databaseId} value={p.databaseId}>
                {p.packageFields.publicTitle}
              </option>
            ))}
        </select>
      </DetailRow>
      <DetailRow>
        Treatment Content:
        <select
          value={patient.phase2 || "-1"}
          onChange={e => handleChange(e.target.value, "2")}
        >
          <option value="-1">Select</option>
          {packages
            ?.filter(p => p.packageFields.phase === "2")
            .map(p => (
              <option key={p.databaseId} value={p.databaseId}>
                {p.packageFields.publicTitle}
              </option>
            ))}
        </select>
      </DetailRow>
      <DetailRow>
        Rehab Content:
        <select
          value={patient.phase3 || "-1"}
          onChange={e => handleChange(e.target.value, "3")}
        >
          <option value="-1">Select</option>
          {packages
            ?.filter(p => p.packageFields.phase === "3")
            .map(p => (
              <option key={p.databaseId} value={p.databaseId}>
                {p.packageFields.publicTitle}
              </option>
            ))}
        </select>
      </DetailRow>
      <DetailRow>
        Current Phase:
        <select
          disabled={!patient.phase1 && !patient.phase2 && !patient.phase3}
          value={patient.currentPhase || "-1"}
          onChange={e =>
            setPatient({
              ...patient,
              currentPhase:
                e.target.value === "-1" ? null : (e.target.value as PhaseType),
            })
          }
        >
          <option value="-1" disabled>
            Select
          </option>
          {patient.phase1 && <option value="1">Phase 1: Care Planning</option>}
          {patient.phase2 && <option value="2">Phase 2: Treatment</option>}
          {patient.phase3 && <option value="3">Phase 3: Rehab</option>}
        </select>
      </DetailRow>

      {modalError && <span className="error-message">{modalError}</span>}

      <button onClick={handleSubmit} disabled={isSubmitDisabled()}>
        {contentTitle}
      </button>
    </Modal>
  )
}

export default PatientModal
