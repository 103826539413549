import "../../styles/table.scss"
import "../../styles/layout.scss"
import StatusBar from "../../components/statusBar"
import { useEffect, useState } from "react"
import moment from "moment"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import PatientModal from "./patientModal"
import { getFormattedBirthDate, getInitials } from "../../utils"
import { fetchPackages, fetchPatients } from "./services"

export type ContentTitle = "Update Content" | "Send Invite"

export type PhaseType = "1" | "2" | "3"

export type Package = {
  databaseId: number
  packageFields: {
    phase: PhaseType
    publicTitle: string
  }
}
export interface PatientData {
  providerId: string
  firstname: string
  lastname: string
  status: 'active' | 'inactive' | 'pending'
  dob: string
  phase1: string | null
  phase2: string | null
  phase3: string | null
  phoneNumber: string
  email: string
  currentPhase: PhaseType | null
  departmentid: string
  // TODO: Fix any;
  appointment: any
}

const PatientList = () => {
  const [error, setError] = useState<string>("")
  const [isFetching, setIsFetching] = useState(true)
  const [packages, setPackages] = useState<Package[]>()
  const [patients, setPatients] = useState<PatientData[]>([])
  const [filteredPatients, setFilteredPatients] = useState<PatientData[]>([])
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedPhase, setSelectedPhase] = useState<PhaseType | "0">("0")
  const [selectedPatient, setSelectedPatient] = useState<PatientData>()
  const [contentTitle, setContentTitle] = useState<ContentTitle>()
  const [nextKey, setNextKey] = useState("")

  // Fetch All Patients and Packages on page load
  const getPackages = async () => {
    try {
      const { data: packageData } = await fetchPackages()
      setPackages(packageData)
    } catch (err) {
      setError("Server error fetching content")
    }
  }

  const getPatients = async () => {
    setError("")
    setIsFetching(true)
    try {
      const res = await fetchPatients({ nextKey })
      setIsFetching(false)
      setPatients(
        nextKey ? [...patients, ...res.data.patients] : res.data.patients
      )
      setNextKey(res.data.next)
    } catch (err: any) {
      setError(err.message)
    }
  }

  const getFilteredPatients = async () => {
    if (selectedPhase === "0") {
      getPatients()
      return
    }
    setError("")
    setIsFetching(true)
    try {
      const res = await fetchPatients({ phaseFilter: selectedPhase }, "search")
      setIsFetching(false)
      setNextKey("")
      // Put in both filter and set patients.  When we search from a filter we dont want to refetch if search term removed so it will be searching from filteredPatients.
      setFilteredPatients(res.data.patients)
      setPatients(res.data.patients)
    } catch (err: any) {
      setError(err.message)
    }
  }

  useEffect(() => {
    getPatients()
    getPackages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilterChange = (phase: PhaseType) => {
    setSelectedPhase(phase)
    getFilteredPatients()
    setSearchTerm("")
  }

  // Search patient by last name
  const handleSearch = async () => {
    if (selectedPhase !== "0") {
      const filteredP = filteredPatients.filter(
        p =>
          p.lastname &&
          p.lastname.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setPatients(filteredP)
      return
    }
    setNextKey("")
    setError("")
    setPatients([])
    setIsFetching(true)
    if (searchTerm) {
      try {
        const { data } = await fetchPatients(
          { searchterm: searchTerm },
          "search"
        )
        setIsFetching(false)
        if (data.errorMessage) {
          setError(data.errorMessage)
        } else if (data.patients.length) {
          setPatients(data.patients)
          setNextKey(data.next)
        }
      } catch (err: any) {
        setError(err.message)
      }
    } else {
      getPatients()
    }
  }

  const sortPackages = (p: any) => {
    const phase1 = packages?.find(r => r.databaseId === parseInt(p.phase1))
    const phase2 = packages?.find(r => r.databaseId === parseInt(p.phase2))
    const phase3 = packages?.find(r => r.databaseId === parseInt(p.phase3))

    const phases: any[] = []

    if (phase1) {
      phases.push(phase1.packageFields.publicTitle)
    }

    if (phase2) {
      phases.push(phase2.packageFields.publicTitle)
    }

    if (phase3) {
      phases.push(phase3.packageFields.publicTitle)
    }

    return phases.join(", ")
  }

  const getCurrentPhase = (currentPhase: PhaseType) => {
    switch (currentPhase) {
      case "1":
        return "CARE PLANNING"
      case "2":
        return "TREATMENT"
      case "3":
        return "REHAB"
    }
  }

  const updatePatientList = (patient: PatientData) => {
    const updatedPatients = [...(patients || [])]
    const patientIndex = updatedPatients.findIndex(
      p => p.providerId === patient.providerId
    )
    updatedPatients[patientIndex] = patient
    setPatients(updatedPatients)
  }

  const closeModal = (updatedPatient?: PatientData, toastText?: string) => {
    if (updatedPatient && toastText) {
      updatePatientList(updatedPatient)
      toast.success(toastText)
    }
    setSelectedPatient(undefined)
    setContentTitle(undefined)
    setSelectedPatient(undefined)
  }
  return (
    <>
      <StatusBar updatedAt={moment()} error={error} isFetching={isFetching} />
      <div style={{ padding: "1rem" }} className="level">
        <div className="level-left">
          <form
            onSubmit={e => {
              e.preventDefault()
              handleSearch()
            }}
          >
            <input
              style={{ width: 300, padding: ".3rem", fontSize: ".9rem" }}
              placeholder="Search for Patients (by last name)"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <button className="button" style={{ marginLeft: 8 }}>
              Search
            </button>
          </form>
        </div>
        <div className="level-right">
          {/* <DepartmentSelector
            selectedDepartment={selectedDepartment}
            isDisabled={isFetching || !!search}
            onChange={(departmentId: any) => setSelectedDepartment(departmentId)}
            setError={onSetError}
          /> */}
          <div style={{ marginLeft: "1rem" }}>
            <select
              name="phases"
              onChange={e => handleFilterChange(e.target.value as PhaseType)}
              value={selectedPhase}
            >
              <option value="0">All phases</option>
              <option value="1">Care Planning</option>
              <option value="2">Treatment</option>
              <option value="3">Rehab</option>
            </select>
          </div>
        </div>
      </div>

      {patients && (
        <div className="table">
          <div className="columns is-vcentered is-mobile header">
            <div className="column">NAME</div>
            <div className="column">DOB</div>
            <div className="column">STATUS</div>
            <div className="column">PACKAGES</div>
            <div className="column">CURRENT PHASE</div>
            <div className="column"></div>
          </div>
          {patients.map((p, i) => (
            <div
              className={`columns is-vcentered is-mobile ${
                i % 2 === 0 ? "gray-bg" : ""
              } `}
              key={p.providerId}
            >
              <div className="column">
                {getInitials(p)} {p.firstname} {p.lastname}
              </div>
              <div className="column">{getFormattedBirthDate(p.dob)}</div>
              <div className="column">
                <div className="badge">{p.status || "UNKNOWN"}</div>
              </div>
              <div className="column">{sortPackages(p)}</div>
              <div className="column">
                {p.currentPhase && (
                  <div className="badge">{getCurrentPhase(p.currentPhase)}</div>
                )}
              </div>
              <div className="column">
                <button
                  className="button black"
                  onClick={() => {
                    setSelectedPatient(p)
                    setContentTitle(
                      p.status === "inactive" ? "Send Invite" : "Update Content"
                    )
                  }}
                >
                  {p.status === "inactive" ? "Send Invite" : "Update Content"}
                </button>
              </div>
            </div>
          ))}
          <div className="level mt-5">
            <div className="level-left">
              Showing {patients.length} result(s)
            </div>
            <div className="level-item">
              {nextKey && (
                <button className="button" onClick={() => getPatients()}>
                  {isFetching ? "Loading..." : "Load More"}
                </button>
              )}
            </div>
          </div>
          {selectedPatient && contentTitle && packages && (
            <PatientModal
              packagesArr={packages}
              selectedPatient={selectedPatient}
              contentTitle={contentTitle}
              closeModal={closeModal}
            />
          )}
          <ToastContainer />
        </div>
      )}
    </>
  )
}

export default PatientList
