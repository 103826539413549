const config = {
  aws: {
    region: "us-east-1",
  },
  auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    userPoolDomain: process.env.REACT_APP_USER_POOL_DOMAIN,
  },
}

export default config
