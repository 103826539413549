import "../styles/status-bar.scss"
import { Moment } from "moment"

const StatusBar = ({
  updatedAt,
  error,
  isFetching,
  isSaving,
}: {
  updatedAt: Moment
  error?: string
  isFetching?: boolean
  isSaving?: boolean
}) => {
  return (
    <div id="status-bar" className="level is-mobile">
      <div className="level-left">
        Updated {updatedAt.format("LL")} at {updatedAt.format("LT")}
      </div>
      <div className="level-item">
        <span className="error-message">{error}</span>
      </div>
      <div className="level-right">
        {isFetching && "Loading..."}
        {isSaving && "Saving..."}
      </div>
    </div>
  )
}

export default StatusBar
