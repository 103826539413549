/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import "../styles/layout.scss"
import "../styles/login.scss"
import { useMemo, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../contexts/User"

const Login = () => {
  const [isFetching, setIsFetching] = useState(false)
  // const [isLoggedIn, setIsLoggedIn] = useState(false)
  // const isLoggedIn = true
  const [error, setError] = useState("")
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("")
  const userContext = useContext(UserContext)

  const navigate = useNavigate()

  const isDisabled = useMemo(() => {
    return !email || !password || isFetching
  }, [email, password, isFetching])

  const onSubmit = async () => {
    setError("")
    setIsFetching(true)
    try {
      const loginSucceeded = await userContext?.signIn({ email, password })

      if (loginSucceeded) navigate("/")
    } catch (err) {
      setError("Email or password not valid")
    }

    setIsFetching(false)
  }

  const onSubmitResetPassword = async () => {
    setError("")
    if (newPassword !== confirmNewPassword)
      setError("Your passwords dont match.")

    await userContext?.confirmResetPassword(newPassword)
    navigate("/")
  }

  if (userContext?.resetPasswordPayload)
    return (
      <div id="login-form">
        <p>Reset Password</p>
        <span className="error-message">{error}</span>
        <input
          placeholder="Password"
          type="password"
          name="newpassword"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <input
          placeholder="Confirm Password"
          type="password"
          name="confirmNewPassword"
          value={confirmNewPassword}
          onChange={e => setConfirmNewPassword(e.target.value)}
        />
        <button
          disabled={isDisabled}
          className="button"
          onClick={onSubmitResetPassword}
        >
          Submit
        </button>
      </div>
    )

  return (
    <div id="login-form">
      <p>Login</p>
      <span className="error-message">{error}</span>
      <input
        placeholder="E-mail"
        type="text"
        name="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <input
        placeholder="Password"
        type="password"
        name="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <button disabled={isDisabled} className="button" onClick={onSubmit}>
        Submit
      </button>
    </div>
  )
}

export default Login
