import "../styles/table.scss"
import "../styles/layout.scss"
import StatusBar from "../components/statusBar"
import { useEffect, useMemo, useState } from "react"
import moment from "moment"
import PhysicianSelector from "../components/physicianSelector"
import api from "../api"

interface AppointmentData {
  appointmentDate: string
  appointmentId: string
  appointmentTime: string
  clinic: string
  createdAt: string
  doctor: string
  doctorId: string
  email?: string
  firstName: string
  lastName: string
  insurance?: string
  dateOfBirth: string
  mobileNumber: string
  notes: string
}

const AppointmentList = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState<string>()
  const [selectedPhysician, setSelectedPhysician] = useState<string>()
  const [appointments, setAppointments] = useState<AppointmentData[]>()

  // Fetch all appointments
  useEffect(() => {
    setIsFetching(true)
    setError(undefined)
    api.get(
      `${process.env.REACT_APP_API_BASE_URL}/admin/appointment`,
      { authorizedRequest: true }
    ).then(response => {
      setIsFetching(false)
      setAppointments(response.data.body)
    }).catch(err => {
      console.log(err)
      setIsFetching(false)
      setError("Server error: Please contact technical support.")
    })
  }, [])

  const filteredAppointments = useMemo(
    () => appointments?.filter(a => a.doctorId === selectedPhysician),
    [appointments, selectedPhysician]
  )

  return (
    <>
      <StatusBar updatedAt={moment()} error={error} isFetching={isFetching} />
      {filteredAppointments && (
        <div className={`table ${isFetching ? "hide" : "show"}`}>
          <PhysicianSelector
            selectedPhysician={selectedPhysician}
            onChange={physician => setSelectedPhysician(physician)}
            setError={setError}
          />
          <div className="columns is-mobile header">
            <div className="column">FIRST NAME</div>
            <div className="column">LAST NAME</div>
            <div className="column is-2">EMAIL</div>
            <div className="column">PHONE</div>
            <div className="column">DOB</div>
            <div className="column">INSURANCE</div>
            <div className="column">APPT DATE</div>
            <div className="column">TIME</div>
            <div className="column">RECEIVED ON</div>
            <div className="column">NOTES</div>
          </div>
          {selectedPhysician &&
            filteredAppointments.map((d, i) => (
              <div
                className={`columns is-mobile ${i % 2 === 0 ? "gray-bg" : ""}`}
                key={d.appointmentId}
              >
                <div className="column">{d.firstName || "-"}</div>
                <div className="column">{d.lastName || "-"}</div>
                <div className="column is-2">{d.email || "-"}</div>
                <div className="column">{d.mobileNumber || "-"}</div>
                <div className="column">{d.dateOfBirth || "-"}</div>
                <div className="column">{d.insurance}</div>
                <div className="column">
                  {d.appointmentDate
                    ? moment(d.appointmentDate).format("L")
                    : "-"}
                </div>
                <div className="column">
                  {moment(d.appointmentTime, "HH:mm").format("h:mm A") || "-"}
                </div>
                <div className="column">
                  {d.createdAt ? moment(d.createdAt).format("L") : "-"}
                </div>
                <div className="column">{d.notes || "-"}</div>
              </div>
            ))}
          {selectedPhysician && (
            <div className="level mt-5">
              <div className="level-left">
                Showing {filteredAppointments.length} result(s)
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default AppointmentList
