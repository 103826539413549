import "../styles/availability.scss"
import moment, { Moment } from "moment"
import { useEffect, useMemo, useState } from "react"
import StatusBar from "../components/statusBar"
import PhysicianSelector from "../components/physicianSelector"
import api from "../api"

const Availability = () => {
  const [startDate, setStartDate] = useState(moment())
  const [timeslots, setTimeslots] = useState<any[]>()
  const [render, setRender] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [updatedAt, setUpdatedAt] = useState(moment())
  const [error, setError] = useState<string>()
  const [selectedPhysician, setSelectedPhysician] = useState<string>()

  // Fetch selected doctor's availability
  useEffect(() => {
    if (selectedPhysician) {
      setIsFetching(true)
      setError(undefined)
      api.get(
        `${process.env.REACT_APP_API_BASE_URL
        }/admin/available-times?doctor=${selectedPhysician}&from=${moment()
          .startOf("isoWeek")
          .format("YYYY-MM-DD")}`,
        { authorizedRequest: true }
      ).then(response => {
        setIsFetching(false)
        setTimeslots(response.data.body)
      }).catch(err => {
        setIsFetching(false)
        setError("Server error: Please contact technical support.")
      })
    }
  }, [selectedPhysician])

  const days = useMemo(() => {
    const first = moment(startDate).startOf("isoWeek")
    const result = [moment(first).startOf("day")]
    for (let i = 0; i < 6; i++) {
      result.push(moment(first.add(1, "days").startOf("day")))
    }
    return result
  }, [startDate])

  const updateTimeTable = (newStartDate: Moment) => {
    setRender(false)
    setTimeout(() => {
      setStartDate(newStartDate.startOf("day"))
      setRender(true)
    }, 200)
  }

  const getTimes = (date: Moment) =>
    timeslots?.find(r => date.isSame(moment(r.date)))?.times || []

  const onChange = async (date: Moment, time: any) => {
    const dateIndex = timeslots?.findIndex(t => date.isSame(moment(t.date)))
    const timeIndex = getTimes(date).findIndex((t: any) => t === time)

    if (dateIndex && dateIndex !== -1 && timeIndex !== -1) {
      const tempArr = timeslots ? [...timeslots] : []
      tempArr[dateIndex].times[timeIndex].available =
        tempArr[dateIndex].times[timeIndex].available === 1 ? 0 : 1
      save(tempArr)
    }
  }

  const updateAll = (date: Moment, value: number) => {
    const dateIndex = timeslots?.findIndex(t => date.isSame(moment(t.date)))
    if (dateIndex && dateIndex !== -1) {
      const tempArr = timeslots ? [...timeslots] : []
      tempArr[dateIndex].times.forEach((t: any) => (t.available = value))
      save(tempArr)
    }
  }

  const save = async (updates: any) => {
    setError(undefined)
    setIsSaving(true)

    try {
      await api.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/update-available-times?doctor=${selectedPhysician}`,
        JSON.stringify({ updates }),
        { authorizedRequest: true }
      )
    } catch {
      setError(
        "Server error: Your selection couldn't be saved. Please contact technical support."
      )
    }

    setIsSaving(false)
    setUpdatedAt(moment())
    setTimeslots(updates)
  }

  const hasPrevTimeslots =
    timeslots?.length &&
    moment(days[0])
      .startOf("day")
      .isAfter(moment(timeslots[0].date).startOf("day"))
  const hasNextTimeslots =
    timeslots?.length &&
    moment(days[6])
      .startOf("day")
      .isBefore(moment(timeslots[timeslots.length - 1].date).startOf("day"))

  return (
    <>
      <StatusBar
        updatedAt={updatedAt}
        error={error}
        isFetching={isFetching}
        isSaving={isSaving}
      />
      <div id="time-selector" className={isFetching ? "hide" : "show"}>
        <PhysicianSelector
          selectedPhysician={selectedPhysician}
          onChange={physician => setSelectedPhysician(physician)}
          setError={setError}
        />
        <div className="level is-mobile">
          <div className="level-left">
            <img
              onClick={() =>
                hasPrevTimeslots
                  ? updateTimeTable(moment(startDate).subtract(7, "days"))
                  : null
              }
              className={`navButton mr-1 is-vcentered ${!hasPrevTimeslots ? "disabled" : ""
                }`}
              src="/images/chevron-left.svg"
              alt=""
            />
          </div>
          <div className="level-item">
            <span className={render ? "show" : "hide"}>
              {`${days?.[0].format("MMM DD")} - ${days?.[6].format(
                "MMM DD YYYY"
              )}`}
            </span>
          </div>
          <div className="level-right">
            <img
              onClick={() =>
                hasNextTimeslots
                  ? updateTimeTable(moment(startDate).add(7, "days"))
                  : null
              }
              className={`navButton mr-1 is-vcentered ${!hasNextTimeslots ? "disabled" : ""
                }`}
              src="/images/chevron-right.svg"
              alt=""
            />
          </div>
        </div>
        <div id="time-table" className={render ? "show" : "hide"}>
          <div id="date-container">
            <div className="columns is-mobile">
              {days?.map((d, i) => (
                <div key={`header-${i}`} className="column">
                  {d.format("ddd, MMM DD").toUpperCase()}
                  <br />
                  {!!getTimes(d).length && (
                    <div>
                      <span onClick={() => updateAll(d, 1)}>All</span> -{" "}
                      <span onClick={() => updateAll(d, 0)}>None</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div id="time-container">
            <div className="columns is-mobile">
              {days?.map((d, i) => {
                const times = getTimes(d)
                if (!times.length) {
                  return (
                    <div key={`timeslots-${i}`} className="column">
                      <div className="has-text-centered disabled">
                        No Availability
                      </div>
                    </div>
                  )
                }
                return (
                  <div key={`timeslots-${i}`} className="column">
                    {times.map((t: any, j: number) => (
                      <div
                        className="time"
                        key={`${i}-${j}`}
                        onClick={() => onChange(d, t)}
                      >
                        <input
                          type="checkbox"
                          readOnly
                          checked={t.available === 1}
                        />
                        <span>{moment(t.time, "HH:mm").format("h:mm A")}</span>
                      </div>
                    ))}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Availability
