import axios from "axios"
import { Auth } from "@aws-amplify/auth"

declare module "axios" {
  export interface AxiosRequestConfig {
    authorizedRequest?: boolean
  }
}

export const PAGE_SIZE = 5

const axiosInstance = axios.create()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authorizeRequest = async (requestConfig: any) => {
  if (!requestConfig.authorizedRequest) return requestConfig

  const user = await Auth.currentAuthenticatedUser()

  // eslint-disable-next-line no-param-reassign
  requestConfig.headers.Authorization = `Bearer ${user.signInUserSession.idToken.jwtToken}`

  return requestConfig
}

axiosInstance.interceptors.request.use(authorizeRequest)

export default axiosInstance
