import * as React from "react"

import "../styles/modal.scss";
import CloseIcon from "../assets/icons/close.svg"

import { ReactNode } from "react"

interface ModalProps {
  children: ReactNode,
  modalId: string,
  theme?: string,
  title?: string,
  isFullScreen?: boolean,
  onClose?: () => void,
}

const Modal = ({ children, modalId, theme, title, isFullScreen, onClose }: ModalProps) => {
  return (
    <div className={`${isFullScreen ? 'full-screen' : ''} ${theme} modal-container`} id={modalId}>
      <div className={`${isFullScreen ? theme : ''} content`}>
        <div className="header level is-mobile">
          <div className="level-left">
            <h4 className="mb-0">{title}</h4>
          </div>
          <div className="level-right">
            <img src={CloseIcon} onClick={onClose} alt="Close" style={{ marginBottom: 0, cursor: "pointer" }} />
          </div>
        </div>
        <hr className="mb-0" />
        <div className="children">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
