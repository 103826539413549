import { useEffect, useState } from "react"
import api from "../api"

interface PhysicianSelectorProps {
  selectedPhysician?: string
  onChange: (physician: any) => void
  setError: (error?: string) => void
}

const PhysicianSelector = ({
  selectedPhysician,
  onChange,
  setError,
}: PhysicianSelectorProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const [physicians, setPhysicians] = useState<any[]>()

  // Fetch all doctors
  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true)
      setError(undefined)
      api.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/doctors`,
        { authorizedRequest: true }
      ).then(response => {
        setIsFetching(false)
        setPhysicians(response.data.body)
        onChange(response.data.body[0].doctorId)
      }).catch(() => {
        setIsFetching(false)
        setError("Server error: Please contact technical support.")
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="has-text-centered">
      <select
        name="provider"
        disabled={isFetching}
        onChange={e => onChange(e.target.value)}
      >
        {physicians?.map(p => (
          <option
            value={p.doctorId}
            selected={selectedPhysician === p.doctorId}
          >
            {p.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default PhysicianSelector
