import api from "../../api"
import { parsePhoneNumber } from "../../utils"
import { PatientData } from "./patientList"

const makePayload = (selectedPatient: PatientData) => ({
  phase1: selectedPatient.phase1 || null,
  phase2: selectedPatient.phase2 || null,
  phase3: selectedPatient.phase3 || null,
  currentPhase: selectedPatient.currentPhase || null,
  providerId: selectedPatient.providerId,
  provider: "drchrono",
  email: selectedPatient.email || null,
})

export const fetchPatients = async (params?: any, route: string = "") => {
  return api.get(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/users/${route}`, {
    authorizedRequest: true,
    params: { provider: "drchrono", ...params },
  })
}

export const fetchPackages = async () => {
  return await api.get(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/packages`, {
    authorizedRequest: true,
  })
}

export const adminInviteUser = async (selectedPatient: PatientData) => {
  const url = `${process.env.REACT_APP_ADMIN_API_BASE_URL}/users`
  const payload = makePayload(selectedPatient)
  const userPayload = {
    ...payload,
    email: selectedPatient?.email,
    phoneNumber: selectedPatient?.phoneNumber
      ? parsePhoneNumber(selectedPatient.phoneNumber)
      : "",
    firstname: selectedPatient.firstname,
    lastname: selectedPatient.lastname,
    dob: selectedPatient.dob,
    providerId: selectedPatient.providerId,
  }
  return await api.post(url, userPayload, { authorizedRequest: true })
}

export const updateUserContent = async (selectedPatient: PatientData) => {
  const url = `${process.env.REACT_APP_ADMIN_API_BASE_URL}/users/content`
  const payload = makePayload(selectedPatient)
  return api.post(url, payload, { authorizedRequest: true })
}
